<template>
    <div id="bingoBall" ref="bingoBall" :class="style">
        <div id="whiteRing">
            <div id="innerRing">
                <p id="number" ref="bingoBallNumber" :class="anim ? `role_position` : ``">{{number}}</p>
            </div>
        </div>
    </div>
</template>

<script>
import { } from 'vue'
export default {
    props: {
        number: Number,
        anim: Boolean,
        position: Number,
        exitAnim: Boolean,
        visibility: {
            type: Boolean,
            default: true
        }
    },
    data: function () {
        return {
            rendered: false
        }
    },
    mounted () {
        this.$nextTick(() => {
            this.rendered = true
        })
        this.$refs.bingoBall.addEventListener('animationend', this.resetAnimBall)
        this.$refs.bingoBallNumber.addEventListener('animationend', this.resetAnimNumber)
        window.addEventListener('resize', this.resize)
        this.$refs.bingoBall.style.width = `${this.$refs.bingoBall.clientHeight}px`
        this.$refs.bingoBallNumber.style.fontSize = `${this.$refs.bingoBall.clientHeight / 3.5}px`
        this.$refs.bingoBall.style.boxShadow = `-${this.$refs.bingoBall.clientHeight / 10}px -${this.$refs.bingoBall.clientHeight / 10}px ${this.$refs.bingoBall.clientHeight / 2}px black inset`
        if (!this.anim) {
            this.$refs.bingoBall.style.opacity = 1
        }
    },
    beforeUnmount () {
        this.$refs.bingoBall.removeEventListener('animationend', this.resetAnim)
        this.$refs.bingoBallNumber.removeEventListener('animationend', this.resetAnimNumber)
        window.removeEventListener('resize', this.resize)
    },
    methods: {
        resize () {
            const height = this.$refs.bingoBall.clientHeight
            this.$refs.bingoBall.style.width = `${height}px`
            this.$refs.bingoBallNumber.style.lineHeight = `${height / 2}px`
            if (this.position === 0 && this.exitAnim) this.$refs.bingoBall.style.left = `-${height}px`
        },
        resetAnimBall () {
            if (this.position === 0 && this.exitAnim) {
                const height = this.$refs.bingoBall.clientHeight
                this.$refs.bingoBall.className = `${this.color} exit`
                this.$refs.bingoBall.style.width = `${height}px`
                this.$refs.bingoBall.style.left = `-${height}px`
            } else {
                this.$refs.bingoBall.className = `${this.color}`
            }
        },
        resetAnimNumber () {
            this.$refs.bingoBallNumber.className = ''
        }
    },
    computed: {
        style () {
            let styling = this.anim ? `${this.color} move_position` : `${this.color} opacity`
            if (!this.visibility) {
                styling += ' hidden'
            }
            return styling
        },
        color () {
            if (this.number <= 15 && this.number > 0) {
                return 'red'
            } else if (this.number <= 30) {
                return 'blue'
            } else if (this.number <= 45) {
                return 'yellow'
            } else if (this.number <= 60) {
                return 'green'
            } else if (this.number <= 75) {
                return 'pink'
            } else {
                return 'black'
            }
        }
    },
    watch: {
        // this is for moving all bingoballs 1 position to the left
        position: function () {
            if (this.position === 0 && this.exitAnim) {
                const height = this.$refs.bingoBall.clientHeight
                this.$refs.bingoBall.className = `${this.color} exit_position exit`
                this.$refs.bingoBall.style.width = `${height}px`
                this.$refs.bingoBall.style.height = `${height}px`
            } else {
                this.$refs.bingoBall.className = this.anim ? this.rendered ? `${this.color} move_position_rendered` : `${this.color} move_position` : `${this.color} opacity`
            }
            this.$refs.bingoBallNumber.className = this.anim ? this.rendered ? 'role_position_rendered' : 'role_position' : ''
        },
        // for triggering a event when balls change
        exitAnim: function () {
            if (this.position === 0 && this.exitAnim) {
                const height = this.$refs.bingoBall.clientHeight
                this.$refs.bingoBall.className = `${this.color} exit_position exit`
                this.$refs.bingoBall.style.width = `${height}px`
                this.$refs.bingoBall.style.height = `${height}px`
            } else {
                this.$refs.bingoBall.className = this.anim ? this.rendered ? `${this.color} move_position_rendered` : `${this.color} move_position` : `${this.color} opacity`
            }
            this.$refs.bingoBallNumber.className = this.anim ? this.rendered ? 'role_position_rendered' : 'role_position' : ''
        }
    }
}
</script>

<style scoped>
    #bingoBall{
        width: 50px;
        height: 100%;
        border-radius: 100%;
        transition: opacity 1s linear;
    }

    .hidden{
        opacity: 0.3 !important;
    }

    .opacity{
        opacity: 0;
    }

    .red{
        background-color: #EF413D;
    }

    .blue{
        background-color: #00AEC7;
    }

    .yellow{
        background-color: #FECA05;
    }

    .green{
        background-color: #01AD4D;
    }

    .pink{
        background-color: #D94B9A;
    }

    #whiteRing{
        width: 60%;
        height: 60%;
        background-color: transparent;
        border: 2px solid white;
        border-radius: 100%;
        position: relative;
        top: 50%;
        margin: auto;
        transform: translateY(-50%);
    }

    #innerRing{
        width: 85%;
        height: 85%;
        background-color: rgb(255 255 255);
        border-radius: 100%;
        position: relative;
        top: 50%;
        margin: auto;
        transform: translateY(-50%);
        box-shadow: -18px -9px 47px #d6d6d6 inset;
        text-align: center;
    }

    #number{
        color: black;
        font-size: 100%;
        margin: 0;
        line-height: 170%;
        user-select: none;
    }

    .move_position{
        animation: linear;
        animation-name: move;
        animation-duration: 4s;
        animation-fill-mode: forwards;
    }

    .move_position_rendered{
        animation: linear;
        animation-name: moveRendered;
        animation-duration: 1s;
        animation-fill-mode: forwards;
    }

    @keyframes move {
        0%   {margin-left: 100vw;}
        100% {margin-left: 0vw;}
    }

    @keyframes moveRendered {
        0%   {margin-left: 100%;}
        100% {margin-left: 0;}
    }

    .role_position_rendered{
        animation: linear;
        animation-name: role;
        animation-duration: 1s;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
    }

    .role_position{
        animation: linear;
        animation-name: role;
        animation-duration: 1s;
        animation-fill-mode: forwards;
        animation-iteration-count: 4;
    }

    @keyframes role {
        100% {
            transform: rotate(-360deg)
        }
    }

    .exit{
        position: absolute;
        top: 0;
        left: 0;
    }

    .exit_position{
        animation: linear;
        animation-name: moveExit;
        animation-duration: 1s;
        animation-fill-mode: forwards;
    }

    /* this fix is not working for small screens. */
    @keyframes moveExit {
        100% {
            left: -20%
        }
    }

</style>
