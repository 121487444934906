<template>
    <div id="container" class="flex flex">
        <div id="GameContainer" class="flex-1 flex">
            <div class="flex-1" id="bingoBallsContainer">
                <div v-if="!mobile" id="incomingBalls" class="flex-3">
                    <div id="bingoBallContainer" v-for="(bingoBall, index) in getLastSixBingoBalls" :key="bingoBall">
                        <BingoBall :number="parseInt(bingoBall)" :exitAnim="exitAnimBingoBall" :anim="true" :position="index"/>
                    </div>
                </div>
                <div v-if="getPlayer" id="drawnBalls" ref="drawnBalls" class="flex-1">
                    <div id="bingoBallContainer" v-for="bingoBall in getReverseBingoBalls" :key="bingoBall">
                        <BingoBall :number="parseInt(bingoBall)" :anim="false"/>
                    </div>
                </div>
            </div>
            <div id="eventContainer" class="flex-3 flex flex-row">
                <div v-if="getPlayer" class="flex-3 flex">
                    <div id="bingoCardsContainer" class="flex-8 flex">
                        <div id="bingoCardColumn" ref="bingoCardColumn" class="flex-1 flex" v-for="i in getBingoCardRows" :key="i">
                            <div class="flex-1">
                                <BingoCard
                                    :editor="false"
                                    :figure="getFigure"
                                    :numbers="getBingoCards[getCardNumberFirstRow(getAmountOfBingoCards, i)]"
                                    :cardNumber="getCardNumberFirstRow(getAmountOfBingoCards, i)"
                                    :selectedNumbers="getselectedNumbers[getCardNumberFirstRow(getAmountOfBingoCards, i)]"
                                    :enable="getBingoBalls.length > 0 && getFakeChallenges[getCardNumberFirstRow(getAmountOfBingoCards, i)] < 2"
                                />
                            </div>
                            <div class="flex-1" v-if="(i * 2) <= getAmountOfBingoCards && getAmountOfBingoCards !== 2">
                                <BingoCard
                                    :editor="false"
                                    :figure="getFigure"
                                    :numbers="getBingoCards[getCardNumberSecondRow(i)]"
                                    :cardNumber="getCardNumberSecondRow(i)"
                                    :selectedNumbers="getselectedNumbers[getCardNumberSecondRow(i)]"
                                    :enable="getBingoBalls.length > 0 && getFakeChallenges[getCardNumberSecondRow(i)] < 2"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="flex-3 flex flex-column">
                    <div id="allBalsContainer">
                        <div class="flex flex-row ballContainerRow" v-for="j in 5" :key="'drawnBallsRow-' + j">
                            <div class="ballContainer" v-for="i in 15" :key="'drawnBallsColumn-' + i">
                                <BingoBall :number="parseInt(((j * 15) + i) - 15)" :visibility="getBingoBalls.includes((((j * 15) + i) - 15).toString())" :anim="false"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="!mobile" id="interactionContainer" class="flex">
                <!--<div id="winnersContainer" class="flex-1">
                        <div id="winnersbord" class="flex">
                            <div class="flex-1 header">
                                <p class="header-text">winners</p>
                            </div>
                            <div v-for="winner in winners" :key="winner.name" class="flex-1 flex row">
                                <div class="flex-3">
                                <p v-if="winner.name" class="winner animate__animated animate__fadeIn">{{winner.name}}</p>
                                </div>
                                <div class="typeContainer flex-1">
                                    <p class="type">{{winner.type}}</p>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <div id="inputContainer" class="flex-3 flex">
                        <div id="chatContainer" class="flex-14">
                            <Chat :player="getPlayer"/>
                        </div>
                        <!-- for admin need to validate -->
                        <div v-if="true" class="flex-1 flex actionContainer">
                            <!-- <div class="flex-1 button" v-if="getAdmin" v-on:click="startGame">
                                <p class="buttonText">▶</p>
                            </div> -->
                            <div class="flex-1 button pauzeColor lb-radius" v-if="getAdmin" v-on:click="pauzeGame" title="Play/pauze game">
                                <p class="buttonText">{{pauze ? '❚❚' : '▶'}}</p>
                            </div>
                            <div :class="getAdmin ? 'flex-1 button danger' : 'flex-1 button danger lb-radius'" v-on:click="handleExit" title="Leave bingo">
                                <p class="buttonText">✖</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <transition
            enter-active-class="animate__animated animate__fadeIn animate__faster"
            leave-active-class="animate__animated animate__fadeOut animate__faster"
            mode="out-in"
        >
        <WinnerModal
            v-if="getWinners['card'] !== undefined"
            :title="'gewonnen'"
            :winners="winners"
            @restart="handleRestart"
            @exit="handleExit"
            :buttons="getAdmin ? ['exit'] : []"
        />
        </transition>
        <Modal :title="`${!getPlayer ? 'game.first8.nl' : 'Game Rules'}`" :bigText="bigText" :description="description()"/>
    </div>
</template>

<script>
import { onMounted, onUnmounted, ref, watch } from 'vue'
import { mapGetters } from 'vuex'
import Chat from '../components/chat/Chat'
import BingoCard from '../components/bingoCard/BingoCard'
import BingoBall from '../components/bingoBall/BingoBall'
import BingoTypes from '../store/modules/bingo/types'
import gameTypes from '../store/modules/game/types'
import chatTypes from '../store/modules/chat/types'
import generalTypes from '../store/types'
import store from '../store'
import router from '../router'
import WinnerModal from '../components/modal/WinnerModal'
import Modal from '../components/modal/Modal'

export default {
    components: {
        Chat,
        BingoCard,
        WinnerModal,
        BingoBall,
        Modal
    },
    data () {
        return {
            firstInit: true
        }
    },
    computed: {
        ...mapGetters('bingo', [
            'getBingoCards',
            'getBingoCardRows',
            'getAmountOfBingoCards',
            'getFigure',
            'getselectedNumbers',
            'getAdmin',
            'getBingoBalls',
            'getFakeChallenges',
            'getWinners',
            'getRestart',
            'getChallenges',
            'getPlayer',
            'getGamePin'
        ]),
        ...mapGetters('websocket', [
            'getClient'
        ]),
        bigText () {
            return (!this.getPlayer && !this.mobile)
        },
        getLastSixBingoBalls () {
            if (this.getBingoBalls.length < 6) {
                return this.getBingoBalls
            } else {
                return this.getBingoBalls.slice(this.getBingoBalls.length - 6, this.getBingoBalls.length)
            }
        },
        exitAnimBingoBall () {
            return this.getLastSixBingoBalls.length >= 6
        },
        getReverseBingoBalls () {
            if (window.matchMedia('(max-width: 320px)')) {
                return this.getBingoBalls.slice(0, this.getBingoBalls.length - 1).reverse()
            } else if (this.getBingoBalls.length < 6) {
                return []
            } else {
                return this.getBingoBalls.slice(0, this.getBingoBalls.length - 5).reverse()
            }
        },
        winners () {
            const array = []
            for (const challenge of this.getChallenges) {
                array.push({ type: challenge, name: this.getWinners[challenge] })
            }
            return array
        }
    },
    mounted () {
        if (this.getPlayer) {
            this.$refs.drawnBalls.addEventListener('wheel', this.scroll, { passive: false })
        }
    },
    beforeUnmount () {
        if (this.getPlayer) {
            this.$refs.drawnBalls.removeEventListener('wheel', this.scroll)
        }
        document.getElementById('background').style.background = 'url("assets/background.jpg");'
    },
    watch: {
        getClient: {
            deep: false,
            handler () {
                if (this.firstInit) {
                    store.dispatch(`bingo/${generalTypes.ONMOUNT}`)
                    store.dispatch(`chat/${chatTypes.ONMOUNT}`)
                    store.dispatch(`bingo/${BingoTypes.RECOVER_DATA}`)
                    this.firstInit = false
                }
            }
        }
    },
    methods: {
        scroll (e) {
            this.$refs.drawnBalls.scrollLeft -= (e.deltaY / 5)
            e.preventDefault()
        },
        handleExit () {
            if (this.getAdmin) {
                store.dispatch('game/' + gameTypes.STOP_GAME)
            } else {
                store.dispatch('game/' + gameTypes.LEAVE_GAME)
                router.push('/lobby')
            }
        },
        description () {
            if (this.getPlayer) {
                return `The bingo will be played with ${this.getBingoCards.length} bingocard${this.getBingoCards.length > 1 ? 's' : ''} per player.

There ${this.getChallenges.length > 1 ? `are ${this.getChallenges.length} challenges:` : 'is 1 challenge:'} 
${this.getChallenges.toString().replaceAll(',', '\n')}

You can click on the bingocard${this.getBingoCards.length > 1 ? 's' : ''} to place a marker on a specific number.

Be carefull! 
You can get fake challenges when you mark wrong numbers.
2 fake challenges and your card will be disabled.
`
            } else {
                return this.getGamePin
            }
        }
    },
    setup () {
        const pauze = ref(false)
        const bingoCardColumn = ref(null)

        const mobile = ref(window.matchMedia('(max-width: 1025px)').matches)

        const getCardNumberFirstRow = (amountOfBingoCards, place) => {
            return amountOfBingoCards === 2 && place === 2 ? 1 : (place * 2) - 2
        }

        onUnmounted(() => {
            window.removeEventListener('resize', resizeEvent)
        })

        const getCardNumberSecondRow = (place) => {
            return (place * 2) - 1
        }

        const resizeBingoCard = () => {
            if (mobile.value) {
                bingoCardColumn.value.style.height = screen.width < window.innerWidth ? `${screen.width * 1.1}px` : `${window.innerWidth * 1.1}px`
            } else {
                bingoCardColumn.value.style.height = ''
            }
        }

        watch(bingoCardColumn, resizeBingoCard)

        const resizeEvent = (e) => {
            mobile.value = window.matchMedia('(max-width: 1025px)').matches
            resizeBingoCard()
        }

        store.watch(
            (state, getters) => getters['bingo/getRestart'],
            (newVal, oldVal) => {
                if (newVal) {
                    pauze.value = false
                    store.dispatch('bingo/' + BingoTypes.GET_BINGO_CARDS)
                    store.dispatch('bingo/' + BingoTypes.GET_FIGURE)
                    store.dispatch('bingo/' + BingoTypes.RESET_RESTART)
                }
            },
            { deep: true }
        )

        onMounted(() => {
            if (store.getters['websocket/getClient']) {
                store.dispatch('bingo/' + BingoTypes.GET_BINGO_CARDS)
                store.dispatch('bingo/' + BingoTypes.GET_FIGURE)
            }
            window.addEventListener('resize', resizeEvent)
        })

        const startGame = () => {
            store.dispatch('game/' + gameTypes.START_GAME)
        }

        const pauzeGame = () => {
            pauze.value = !pauze.value
            store.dispatch('game/' + gameTypes.PAUZE_GAME)
        }

        const handleRestart = () => {
            store.dispatch('game/' + gameTypes.RESTART_GAME)
        }

        return {
            startGame, pauzeGame, handleRestart, getCardNumberFirstRow, getCardNumberSecondRow, pauze, mobile, bingoCardColumn
        }
    }
}
</script>

<style scoped>
    @media (min-width:320px){
        #eventContainer{
            flex: 10;
        }

        #drawnBalls{
            padding-top: 5px;
        }

        #bingoCardColumn{
            height: calc(100vw * 1.1);
        }
    }

    @media (min-width:1025px) {
        #eventContainer{
            flex: 3
        }

        #drawnBalls{
            padding-top: 0px;
        }

        #bingoCardColumn{
            height: 100%;
        }
    }

    #container{
        width: 100vw;
        height: 100vh;
        flex-direction: row;
        background-color: #00000069;
        /* background:
        radial-gradient(hsl(0, 100%, 27%) 4%, hsl(0, 100%, 18%) 9%, hsla(0, 100%, 20%, 0) 9%) 0 0,
        radial-gradient(hsl(0, 100%, 27%) 4%, hsl(0, 100%, 18%) 8%, hsla(0, 100%, 20%, 0) 10%) 50px 50px,
        radial-gradient(hsla(0, 100%, 30%, 0.8) 20%, hsla(0, 100%, 20%, 0)) 50px 0,
        radial-gradient(hsla(0, 100%, 30%, 0.8) 20%, hsla(0, 100%, 20%, 0)) 0 50px,
        radial-gradient(hsla(0, 100%, 20%, 1) 35%, hsla(0, 100%, 20%, 0) 60%) 50px 0,
        radial-gradient(hsla(0, 100%, 20%, 1) 35%, hsla(0, 100%, 20%, 0) 60%) 100px 50px,
        radial-gradient(hsla(0, 100%, 15%, 0.7), hsla(0, 100%, 20%, 0)) 0 0,
        radial-gradient(hsla(0, 100%, 15%, 0.7), hsla(0, 100%, 20%, 0)) 50px 50px,
        linear-gradient(45deg, hsla(0, 100%, 20%, 0) 49%, hsla(0, 100%, 0%, 1) 50%, hsla(0, 100%, 20%, 0) 70%) 0 0,
        linear-gradient(-45deg, hsla(0, 100%, 20%, 0) 49%, hsla(0, 100%, 0%, 1) 50%, hsla(0, 100%, 20%, 0) 70%) 0 0;
        background-color: #300;
        background-size: 100px 100px; */
    }

    .biggerText{
        font-size: 30px;
    }

    #buttonContainer{
        border-top: 1px solid #adacac;
    }

    #winnersbord{
        height: 90%;
        background-color: rgba(66, 66, 66, 0.493);
        backdrop-filter: blur(2px);
        box-shadow: -5px 3px 10px rgba(0, 0, 0, 0.459);
        border-bottom-left-radius: 15px;
        flex-direction: column;
    }

    #GameContainer{
        flex-direction: column;
        /* background: linear-gradient(90deg, rgba(0,0,0,0.8) 0%,  rgba(0,0,0,0) 5%,   rgba(0,0,0,0) 95%, rgba(0,0,0,0) 100%); */
    }

    #bingoBallContainer{
        height: 100%;
    }

    #interactionContainer{
        width: 300px;
        flex-direction: column;
        /* background: linear-gradient(0deg, rgba(41,0,0,1) 0%, rgb(0, 0, 0, 1) 80%,  rgb(0, 0, 0, 1) 100%); */
        /* box-shadow: -40px 0px 40px 0px black; */
        z-index: 1;
        box-shadow: -10px 10px 20px rgba(29, 29, 29, 0.486);
        border-top-right-radius: 15px;
        border-top-left-radius: 15px;
    }

    #incomingBalls{
        display: flex;
        height: 100%;
        width: 100vw;
        overflow: hidden;
        flex-direction: row;
        /* border-bottom: 1px solid black; */
        /* background: linear-gradient(90deg, rgba(0,0,0,0.5) 0%,  rgba(0,0,0,0) 5%,   rgba(0,0,0,0) 95%, rgba(0,0,0,0.5) 100%), linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(144,0,0,1) 50%, rgba(0,0,0,1) 100%); */
    }

    #drawnBalls{
        display: flex;
        flex-direction: row;
        width: 100vw;
        overflow: scroll;
        /* background: linear-gradient(0deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,1) 100%); */
    }

    #allBalsContainer{
         margin: auto;
    }

    #drawnBalls::-webkit-scrollbar{
        display: none;
    }

    .flex{
        display: flex;
    }

    .flex-column{
        flex-direction: column;
    }

    .flex-row{
        flex-direction: row;
    }

    .flex-1{
        flex: 1;
    }

    .flex-3{
        flex: 3;
    }

    .flex-8{
        flex: 8;
    }

    .flex-9{
        flex: 9;
    }

    .flex-2{
        flex: 2;
    }

    .flex-14{
        flex: 14;
    }

    .lb-radius{
        border-bottom-left-radius: 10px;
    }

    #bingoBallsContainer{
        display: flex;
        flex-direction: column;
    }

    #bingoCardsContainer{
        /* background:
            linear-gradient(0deg, rgba(0,0,0,0.5) 0%,  rgba(0,0,0,0) 5%,   rgba(0,0,0,0) 95%, rgba(0,0,0,0.5) 100%); */
        background:
            linear-gradient(0deg, rgba(0,0,0,0.5) 0%,  rgba(0,0,0,0) 5%,   rgba(0,0,0,0) 95%, rgba(0,0,0,0) 100%);
    }

    #chatContainer{
        overflow: auto;
    }

    .danger{
        background-color: rgba(255, 0, 0, 0.425) !important;
    }

    .danger:hover{
        background-color: rgba(255, 0, 0, 0.527) !important;
    }

    #inputContainer{
        flex-direction: column;
    }

    #adminContainer{
        background-color: black;
    }

    .ballContainerRow{
        margin: auto;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .pauzeColor{
        background-color: #44ccebc0;
    }

    .ballContainer{
        width: calc((100vw - 700px) / 15);
        height: calc((100vw - 700px) / 15);
        padding: 10px;
    }

    .buttonText{
        margin: 0;
        position: relative;
        top: 50%;
        color: white;
        font-size: 15px;
        user-select: none;
        transform: translateY(-50%);
    }

    .button{
        width: 100%;
        height: 100%;
        text-align: center;
        transition: all 0.2s linear;
    }

    .left{
        border-bottom-left-radius: 15px;
    }

    .right{
        border-bottom-right-radius: 15px;
    }

    .button:hover{
        cursor: pointer;
        background-color: rgba(66, 209, 241, 0.877);
    }

    #bingoCardColumn{
        flex-direction: column;
        margin: auto;
    }

    .row{
        flex-direction: row;
        border-bottom: 1px solid;
        border-image: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 10%, rgba(75, 75, 75) 40%, #727272 60%, #F5F5DC 88%, #F5F5DC 100%) 30;
        border-right: 1px solid #F5F5DC;
        border-left: 1px solid #F5F5DC;
    }

    .row:last-child{
        border-bottom: 0;
    }

    .winner{
        margin: 0;
        text-align: center;
        position: relative;
        top: 50%;
        font-size: 20px;
        transform: translateY(-50%);
    }

    .type{
        margin: 0;
        position: relative;
        top: 50%;
        text-align: center;
        color: white;
        transform: translateY(-50%);
    }

    .typeContainer{
        background-color: rgba(0, 0, 0, 0.219);
        box-shadow: -5px 0px 10px rgba(0, 0, 0, 0.425);
    }

    .header{
        background-color: rgba(0, 0, 0, 0.3);
        box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.425);
        max-height: 40px;
    }

    .header-text{
        font-size: 25px;
        margin: 0;
        text-align: center;
        position: relative;
        top: 50%;
        color: white;
        transform: translateY(-50%);
    }

    .actionContainer{
        background-color: rgba(41, 41, 41, 0.788);
        backdrop-filter: blur(5px);
    }
</style>
