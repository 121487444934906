<template>
    <div id="backgroundModal" class="animate__animated animate__fadeIn">
        <canvas id="canvas"></canvas>
        <div id="Modal" class="animate__animated animate__fadeIn animate__delay-1s">
            <Podium :winners="winners"/>
            <div id="buttonContainer">
                <div class="button" v-for="button in buttons" :key="button" v-on:click="action(button)">
                    <p class="buttonText">{{button}}</p>
                </div>
            </div>
            <div>
                <div id="creator" >
                    <img id="creatorImage" unselectable="on" src='../../assets/first8_white.png'>
                </div>
                <p id="creatorText">This Application is made by: Rick van Berlo</p>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, onUnmounted } from 'vue'
import { setup, loop, clear } from '../../utils/fire'
import Podium from '../podium/Podium'

export default {
    components: {
        Podium
    },
    props: {
        title: String,
        // winners is orders from 1ste to last. the first winner in the array will be placed on as 1ste on the podium
        winners: Array,
        buttons: Array
    },
    setup (props, { emit }) {
        let interval
        const action = (button) => {
            emit(button)
        }

        onMounted(() => {
            setup()
            interval = setInterval(loop, 1 / 60)
        })

        onUnmounted(() => {
            clear()
            clearInterval(interval)
        })

        return {
            action
        }
    }
}
</script>

<style scoped>
    @media (min-width:320px){
        #Modal{
            width: 90vw;
            height: 600px;
        }

        #description{
            font-size: 15px;
        }
    }

    @media (min-width:1025px) {
        #Modal{
            width: 500px;
            height: 600px;
            }

        #description{
            font-size: 18px;
        }
    }

    #backgroundModal{
        position: absolute;
        top:0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 10;
    }

    #canvas{
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        opacity: 0.9;
    }

    .flex{
        display: flex;
    }

    #Modal{
        position: relative;
        top: 50%;
        padding-bottom: 10px;
        background-color: #ffffff21;
        margin: auto;
        transform: translateY(-50%);
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.712);
        border-radius: 10px;
        backdrop-filter: blur(5px);
        display: flex;
        flex-direction: column;
        z-index: 100;
    }

    #titleContainer{
        flex: 1;
        text-align: center;
    }

    #buttonContainer{
        display: flex;
        flex-direction: row;
        margin: auto;
        width: 80%;
        justify-content: center;
    }

    .buttonText{
        position: relative;
        top: 50%;
        margin: 0;
        transform: translateY(-50%);
        user-select: none;
    }

    .button{
        flex: 1;
        height: 35px;
        margin-top: 10px;
        margin-right: 5px;
        text-align: center;
        color: white;
        border-radius: 15px;
        font-size: 18px;
        background-color: #c3c3c346;
        transition: all 0.2s linear;
    }

    .button:hover{
        cursor: pointer;
        background-color: #a0a0a083;
    }

    #creator{
        position: fixed;
        top: 10px;
        left: 10px;
    }

    #creatorImage{
        height: 20px;
    }

    #creatorText{
        margin:1px;
        margin-top: 15px;
        text-align: center;
        color: white;
    }
</style>
