<template>
    <div id="chatmodule" class="flex">
        <div id="buttonsContainer" class="flex-1 flex">
            <div :class="view === 'chat' ? 'button flex-1 active' : 'button flex-1'" v-on:click="setChatView">
                <p :class="view === 'chat' ? 'buttonTextActive' : 'buttonTextInactive'">chat</p>
            </div>
            <div :class="view === 'users' ? 'button flex-1 active' : 'button flex-1'" v-on:click="setUsersView">
                <p :class="view === 'users' ? 'buttonTextActive' : 'buttonTextInactive'">users</p>
            </div>
        </div>
        <div id="messagesContainer" class="flex-12">
                <transition
                v-on:enter="onEnter"
                enter-active-class="animate__animated animate__fadeIn animate__faster"
                leave-active-class="animate__animated animate__fadeOut animate__faster"
                mode="out-in"
                >
                    <div v-if="view === 'chat'" id="chat" class="flex">
                        <div class="overflow flex-1 mb-1" ref="messages">
                            <div class="overflowContainer">
                                <div id="chatContainer" class="animate__animated animate__fadeIn animate__faster flex" v-for="message in getMessages" :key="message">
                                    <p id="name">{{ message[0] }}:</p>
                                    <div class="flex-1">
                                        <p id="message">{{ message[1] }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="player" id="sendMessageContainer" class="flex animate__animated animate__fadeIn animate__faster">
                            <div id="inputContainer" class="flex-8">
                                <input id="input" type="text" ref="input" autocomplete="off" placeholder="Enter message" @keyup.enter="sendMessage"/>
                            </div>
                            <div id="buttonContainer" class="flex-2">
                                <div id="submitButton" v-on:click="sendMessage">
                                    <p id="submitButtonText" title="Send message">➤</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else-if="view === 'users'" id="chat" class="flex">
                        <div class="overflow flex-1 mb-1" ref="messages">
                            <div class="overflowContainer">
                                <div id="chatContainer" class="animate__animated animate__fadeIn animate__faster flex" v-for="user in getUsers" :key="user">
                                    <div class="flex-1">
                                        <p id="user">{{ user[1] }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="sendMessageContainer" class="flex animate__animated animate__fadeIn animate__faster">
                            <div id="inputContainer" class="flex-8">
                               <p class="counterUsers">
                                    amount of users: {{getUsers.length}}
                                </p>
                            </div>
                        </div>
                        <!-- <div class="overflowContainer">
                            <transition-group
                                v-on:enter="onEnter"
                                enter-active-class="animate__animated animate__fadeIn animate__faster"
                                leave-active-class="animate__animated animate__fadeOut animate__faster"
                                mode="out-in"
                            >
                            <div id="userContainer" class="animate__animated animate__fadeIn animate__faster" v-for="user in getUsers" :key="user">
                                <p id="user">{{ user[1] }}</p>
                            </div>
                            </transition-group>
                        </div>
                        <p class="counterUsers">
                            amount of users: {{getUsers.length}}
                        </p> -->
                    </div>
                </transition>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ref } from 'vue'
import types from '../../store/modules/chat/types'
import store from '../../store'

export default {
    props: {
        player: Boolean
    },
    data: function () {
        return {
            view: 'chat'
        }
    },
    computed: {
        ...mapGetters('chat', [
            'getMessages',
            'getUsers'
        ])
    },
    methods: {
        setChatView () {
            this.view = 'chat'
            this.resetScroll = true
        },
        setUsersView () {
            this.view = 'users'
        },
        onEnter (el, done) {
            if (el.id === 'chat') {
                this.$refs.messages.classList.remove('smooth-scrolling')
                this.$refs.messages.scrollTop = this.$refs.messages.scrollHeight
                done()
            }
        }
    },
    watch: {
        getMessages: {
            deep: true,
            handler () {
                if (this.view === 'chat') {
                    this.$refs.messages.classList.add('smooth-scrolling')
                    this.$nextTick(() => {
                        this.$refs.messages.scrollTop = this.$refs.messages.scrollHeight
                    })
                }
            }
        }
    },
    setup (props) {
        const input = ref()

        const sendMessage = () => {
            store.dispatch(`chat/${types.SEND_MESSAGE}`, input.value.value)
            input.value.value = ''
        }

        return {
            input, sendMessage
        }
    }
}
</script>

<style scoped>
    #chatmodule{
        width: 100%;
        height: 100%;
        flex-direction: column;
        overflow:auto;
        border-top-right-radius: 15px;
        border-top-left-radius: 15px;
    }

    #chat{
        flex-direction: column;
        height: 100%;
    }

    .smooth-scrolling{
        scroll-behavior: smooth;
    }

    .flex{
        display: flex;
    }

    .flex-1{
        flex: 1;
    }

    .flex-3{
        flex: 3;
    }

    .flex-8{
        flex: 8;
    }

    .flex-9{
        flex: 9;
    }

    .flex-2{
        flex: 2;
    }

    .flex-12{
        flex: 12;
    }

    .overflow{
        overflow: scroll;
    }

    .flex-column{
        flex-direction: column;
    }

    .counterUsers{
        margin: 0px;
        padding: 10px 0px 10px 0px;
        text-align: center;
        color: rgb(228, 228, 228);
        /* border-image: linear-gradient(90deg, rgba(0,0,0,0) 0%,  rgba(0,0,0,0) 12%,  rgba(75, 75, 75) 40%,  rgb(75, 75, 75) 60%,  rgba(0,0,0,0) 88%, rgba(0,0,0,0) 100%) 30; */
    }

    #sendMessageContainer{
        /* background-color: #F5F5DC; */
        padding-bottom: 5px;
        width: 98%;
        margin: auto;
    }

    #messagesContainer{
        background-color: rgba(41, 41, 41, 0.404);
        backdrop-filter: blur(5px);
        overflow: scroll;
        /* border-image: linear-gradient(90deg, rgba(0,0,0,0) 0%,  rgba(0,0,0,0) 12%,  rgba(75, 75, 75) 40%,  rgb(75, 75, 75) 60%,  rgba(0,0,0,0) 88%, rgba(0,0,0,0) 100%) 30; */
    }

    .overflowContainer{
        height: 0;
        min-height: -webkit-min-content;
    }

    #messagesContainer::-webkit-scrollbar{
        display: none;
    }

    .overflow::-webkit-scrollbar{
        display: none;
    }

    #buttonContainer{
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        box-shadow: 0px 1px 2px black;
    }

    #submitButton{
        width: 100%;
        height: 100%;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        text-align: center;
        background-color: #0256b680;
        transition: all .2s ease-in-out;
    }

    #submitButton:hover{
        cursor: pointer;
        background-color: #004697cc;
    }

    #name{
        margin: 0px;
        padding-left: 10px;
    }

    #message{
        margin: 0px;
        text-align: left;
        padding-left: 5px;
        width: 90%;
    }

    #user{
        margin: auto;
        margin-top: 0;
        margin-bottom: 0;
        text-align: center;
        padding-left: 5px;
        width: 90%;
    }

    #submitButtonText{
        margin: 0;
        position: relative;
        color: white;
        top: 50%;
        font-size: 30px;
        transform: translateY(-50%);
    }

    #input{
        width: 100%;
        height: 100%;
        font-size: 15px;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        border: none;
        padding-left: 10px;
        background-color: rgba(26, 25, 25, 0.562);
        padding-right: 10px;
        color: white;
        box-shadow: 0px 1px 2px black;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

    #input::placeholder{
        color: rgb(190, 190, 190);
    }

    #input:focus{
        outline: none;
    }

    #chatContainer{
        height: fit-content;
        width: 95%;
        margin: auto;
        background-color: #005dc89c;
        box-shadow: 0px 2px 2px black;
        padding-top: 10px;
        padding-bottom: 10px;
        border: none;
        text-align: center;
        color: white;
        margin-bottom: 7px;
        border-radius: 20px;
        display: flex;
    }

    #userContainer{
        height: fit-content;
        width: 95%;
        margin: auto;
        display: flex;
        background-color: #005CC8;
        box-shadow: 0px 2px 2px black;
        padding-top: 10px;
        padding-bottom: 10px;
        border: none;
        text-align: center;
        color: white;
        margin-bottom: 7px;
        border-radius: 100000px;

    }

    #buttonsContainer{
        /* background: linear-gradient(0deg, rgba(245,245,220,1) 0%, rgba(245,245,220,1) 50%, rgba(0,0,0,0) 50%, rgb(255 255 255 / 0%) 100%); */
        /* padding-bottom: 5px; */
        border-top-right-radius: 15px;
        border-top-left-radius: 15px;
    }

    .button{
        width: 100%;
        height: 100%;
        border-top-right-radius: 15px;
        border-top-left-radius: 15px;
        text-align: center;
        background-color: rgba(41, 41, 41, 0.404);
        backdrop-filter: blur(5px);
        cursor: pointer;
    }

    .buttonTextActive{
        margin: 0;
        position: relative;
        top: 50%;
        color:rgb(226, 226, 226);
        font-size: 20px;
        transform: translateY(-50%);
        user-select: none;
    }

    .buttonTextInactive{
        margin: 0;
        position: relative;
        top: 50%;
        color:#e2e2e2;
        font-size: 20px;
        transform: translateY(-50%);
        user-select: none;
    }

    .active{
        /* background-color: #13131388; */
        background-image: linear-gradient(0deg, rgba(41, 41, 41, 0) 0%, rgba(41, 41, 41, 0.104) 30%,rgba(41, 41, 41, 0.404) 100%);

    }
    .inactive-right{
        border-bottom-left-radius: 15px;
    }
    .inactive-left{
        border-bottom-right-radius: 15px;
    }

    .mb-1{
        margin-bottom: 5px;
    }
</style>
