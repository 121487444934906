<template>
    <div id="winnersContainer">
        <div v-for="winner in positionedWinners" :key="winner" class="flex-1 flex column">
            <p :style="{ marginTop: `calc(${winner.place} * 50%)` }" id="winner">{{winner.name}}</p>
            <div class=" flex-1 pillar" :style="{ zIndex: 5 - winner.place }">
                <p class="type">{{winner.type}}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        // winners is orders from 1ste to last. the first winner in the array will be placed on as 1ste on the podium
        winners: Array
    },
    computed: {
        positionedWinners () {
            const array1 = []
            const array2 = []
            for (let i = 0; i < this.winners.length; i += 2) {
                const winner1 = this.winners[i]
                winner1.place = i
                array1.push(winner1)
                if ((i + 1) < this.winners.length) {
                    const winner2 = this.winners[i + 1]
                    winner2.place = i + 1
                    array2.push(winner2)
                }
            }
            const reverseArray2 = array2.reverse()
            return reverseArray2.concat(array1)
        }
    }
}
</script>

<style scoped>
    .flex{
        display: flex;
    }

    .flex-1{
        flex: 1;
    }

    .column{
        flex-direction: column;
    }

    .pillar{
        background-color: #0066ff9f;
        text-align: center;
        width: 95%;
        box-shadow: 0px 0px 34px black;
        max-width: 100px;
        margin: auto;
        transition: all 0.3s linear;
    }

    #winnersContainer{
        width: 90%;
        height: 100%;
        padding-top: 50px;
        margin: auto;
        white-space: pre-line;
        display: flex;
        flex-direction: row;
    }

    #winner{
        text-align: center;
        color: white;
        font-size: 20px;
        user-select: none;
        margin: 0;
        height: 25px;
        text-shadow: 0px 0px 10px black;
        z-index: 6;
    }

    .type{
        user-select: none;
        margin: 0;
        margin-top: 5px;
    }
</style>
