<template>
    <transition
        enter-active-class="animate__animated animate__fadeIn animate__faster"
        leave-active-class="animate__animated animate__fadeOut animate__faster"
        mode="out-in"
    >
    <div v-if="!close" id="backgroundModal" @click="exit">
        <div id="Modal" class="animate__animated animate__fadeIn animate__delay-1s" @click="nothing">
            <div id="titleContainer">
                <p id="title">{{title}}</p>
            </div>
            <div id="descriptionContainer">
                <p id="description" :class="`${checkBigText ? 'bigText' : ''}`">{{description}}</p>
            </div>
            <div id="buttonContainer">
                <div class="button" v-on:click="exit">
                    <p class="buttonText">close</p>
                </div>
            </div>
        </div>
    </div>
    </transition>
</template>

<script>
export default {
    props: {
        title: String,
        description: String,
        bigText: {
            value: Boolean,
            default: false
        }
    },
    data: function () {
        return {
            close: false
        }
    },
    methods: {
        exit () {
            this.close = true
        },
        nothing (e) {
            e.stopPropagation()
        }
    },
    computed: {
        checkBigText () {
            return this.bigText
        }
    }
}
</script>

<style scoped>
    @media (min-width:320px){
        #Modal{
            width: 90vw;
            height: 500px;
        }

        #description{
            font-size: 15px;
        }
    }

    @media (min-width:1025px) {
        #Modal{
            width: 500px;
            height: 500px;
            }

        #description{
            font-size: 18px;
        }
    }

    .bigText{
        font-size: 100px !important;
    }

    #backgroundModal{
        position: absolute;
        top:0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 10;
        background-color: #000000b5;
    }

    #description{
        color: rgb(231, 231, 231);
        user-select: none;
        white-space: pre-wrap;
    }

    .flex-1{
        flex: 1;
    }

    .flex{
        display: flex;
    }

    .column{
        flex-direction: column;
    }

    #pillar{
        background-color: #0066ff96;
        text-align: center;
        width: 95%;
        box-shadow: 0px 0px 34px black;
        max-width: 100px;
        margin: auto;
    }

    #Modal{
        position: relative;
        top: 50%;
        padding-bottom: 10px;
        background-color: #ffffff25;
        margin: auto;
        transform: translateY(-50%);
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.712);
        border-radius: 10px;
        backdrop-filter: blur(5px);
        display: flex;
        flex-direction: column;
        z-index: 100;
    }

    #titleContainer{
        flex: 1;
        text-align: center;
    }

    #title{
        font-size: 50px;
        margin: 0;
        color: #0066ffb6;
        padding-top: 10px;
        text-shadow: 0px 4px 9px black;
        user-select: none;
    }

    #descriptionContainer{
        width: 90%;
        margin: auto;
        text-align: center;
        flex: 4;
    }

    #buttonContainer{
        display: flex;
        flex-direction: row;
        margin: auto;
        width: 80%;
        justify-content: center;
    }

    .buttonText{
        position: relative;
        top: 50%;
        margin: 0;
        transform: translateY(-50%);
        user-select: none;
    }

    .button{
        flex: 1;
        height: 35px;
        margin-top: 10px;
        margin-right: 5px;
        text-align: center;
        color: white;
        border-radius: 15px;
        font-size: 18px;
        background-color: #c3c3c356;
        transition: all 0.2s linear;
    }

    .button:hover{
        cursor: pointer;
        background-color: #a0a0a0a1;
    }
</style>
